import { PropsWithChildren } from 'react'
import PropTypes from 'prop-types'
import { Alignment, Label } from '@resident-advisor/design-system'

const HorizontalLabel = ({
  children,
  disabled,
  displayProps,
}: PropsWithChildren<HorizontalLabelProps>) => {
  return (
    <Label {...displayProps} disabled={disabled}>
      <Alignment flexdirection="row" alignItems="center">
        {children}
      </Alignment>
    </Label>
  )
}

const horizontalLabelPropTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  displayProps: PropTypes.object,
}

HorizontalLabel.propTypes = horizontalLabelPropTypes

type HorizontalLabelProps = PropTypes.InferProps<
  typeof horizontalLabelPropTypes
>

export default HorizontalLabel
