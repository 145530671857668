import PropTypes from 'prop-types'
import {
  TextInput,
  HideIcon,
  ShowIcon,
  fontSizes,
} from '@resident-advisor/design-system'
import { useState } from 'react'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import { Field } from '@/components/forms'
import FieldFunction from '../field-function/FieldFunction'

const PasswordInput = ({ ...props }) => {
  const [passwordShown, setPasswordShown] = useState(false)
  const togglePasswordVisiblity = (newPasswordShow) => {
    setPasswordShown(newPasswordShow)
    if (newPasswordShow) {
      Tracking.trackMixpanel(TRACKING_EVENT.revealPassword)
    }
  }

  const lineHeight = fontSizes.small.label.lineHeight.replace('px', '')
  const iconSize = lineHeight * 1.7

  return (
    <Field
      Component={TextInput}
      name={props.name}
      type={passwordShown ? 'text' : 'password'}
      FieldFunctionComponent={() => (
        <FieldFunction
          onClick={() => togglePasswordVisiblity(!passwordShown)}
          text={passwordShown ? 'Hide' : 'Show'}
          icon={passwordShown ? HideIcon : ShowIcon}
          color="accent"
          size={iconSize}
          iconStyles={{ bottom: `${(iconSize - lineHeight) / 2}px` }}
        />
      )}
      {...props}
    />
  )
}

PasswordInput.propTypes = {
  name: PropTypes.string,
}

PasswordInput.defaultProps = {
  name: 'password',
}

export default PasswordInput
