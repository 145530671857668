// eslint-disable-next-line no-restricted-imports
import { Form } from 'formik'
import PropTypes from 'prop-types'
import useErrorFocus from '@/hooks/useErrorFocus'
import useGenericErrorHandling from '@/hooks/useGenericErrorHandling'
import testIds from '@/enums/testIds'

const FormikForm = ({ children, ...props }) => {
  useErrorFocus()
  useGenericErrorHandling()

  return (
    <Form noValidate method="POST" {...props} data-testid={testIds.formikForm}>
      {children}
    </Form>
  )
}

FormikForm.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FormikForm
