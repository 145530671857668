import {
  Icon,
  Text,
  Alignment,
  variants,
  TextHeight,
  Box,
} from '@resident-advisor/design-system'

const FieldFunction = ({
  onClick,
  icon,
  text,
  iconStyles,
  color = 'accent',
  size = 24,
  style = {},
  ...props
}: FieldFunctionProps) => {
  return (
    <Box style={style} {...props}>
      <TextHeight variant={variants.text.label}>
        <Alignment
          justifyContent="space-between"
          onClick={onClick}
          style={{ cursor: 'pointer' }}
          role="button"
        >
          <Text color={color} variant={variants.text.label} pr={1}>
            {text}
          </Text>
          <Icon
            color={color}
            Component={icon}
            size={size}
            position="relative"
            inline
            {...iconStyles}
          />
        </Alignment>
      </TextHeight>
    </Box>
  )
}

type FieldFunctionProps = {
  text?: string
  color?: string
  size: number
  icon: () => JSX.Element
  onClick: () => void
  iconStyles: object
  style: object
}

export default FieldFunction
