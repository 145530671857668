const formatValueWithExponent = (
  value: string | number,
  exp: number
): string => {
  if (!value) return null
  if (value) {
    const amount = typeof value === 'number' ? value.toString() : value
    const splitNumber = amount.split('.')

    if (splitNumber.length > 1) {
      const exponent = exp ?? 2
      // for 0 places currencies just return the whole number
      if (exponent === 0) return splitNumber[0]

      return `${splitNumber[0]}.${splitNumber[1].substring(0, exponent)}`
    }
  }

  return value.toString()
}

export default formatValueWithExponent
