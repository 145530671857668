import { TextInput } from '@resident-advisor/design-system'
import { Field } from '@/components/forms'
import { FormType } from '@/enums/form-type'

const IntegerField = ({
  name,
  ToolTip,
  label,
  formType,
  indicateRequired = false,
  placeholder,
  fieldComponentProps,
  ...props
}: {
  name: string
  label?: string
  ToolTip?: React.FC
  formType?: FormType
  indicateRequired?: boolean
  placeholder?: string
  fieldComponentProps?: unknown
}) => {
  return (
    <Field
      {...props}
      label={label}
      ToolTip={ToolTip}
      name={name}
      Component={TextInput}
      type="number"
      indicateRequired={indicateRequired}
      formType={formType}
      placeholder={placeholder}
      fieldComponentProps={fieldComponentProps}
      onKeyDown={(e) => {
        // Restrict input to integers by preventing 'e', '.', and '-' characters
        if (e.key === 'e' || e.key === '.' || e.key === '-') {
          e.preventDefault()
        }
      }}
    />
  )
}

export default IntegerField
