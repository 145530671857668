import { useIntl } from 'react-intl'
import { Text, variants } from '@resident-advisor/design-system'
import messages from '@/messages/auth'

const RequiredKey = () => {
  const intl = useIntl()
  return (
    <>
      <Text color="accent" variant={variants.text.label}>
        *
      </Text>
      <Text color="secondary" variant={variants.text.label}>
        {intl.formatMessage(messages.requiredKey)}
      </Text>
    </>
  )
}

export default RequiredKey
