import PropTypes from 'prop-types'
import { FormProvider } from 'react-hook-form'
import FORM_TYPE from '@/enums/form-type'
import FormikForm from './FormikForm'

const Form = ({ children, type, ...props }) => {
  if (type === FORM_TYPE.formik) {
    return (
      <FormikForm aria-label="form" {...props}>
        {children}
      </FormikForm>
    )
  }

  return (
    <FormProvider aria-label="form" {...props}>
      {children}
    </FormProvider>
  )
}

Form.defaultProps = {
  type: FORM_TYPE.formik,
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(FORM_TYPE)),
}

export default Form
