import PropTypes from 'prop-types'
import { Label, Alignment } from '@resident-advisor/design-system'
import useFormField from '@/hooks/useFormField'
import FORM_TYPE, { FormType } from '@/enums/form-type'

const Field: React.FC<Props> = ({
  Component,
  label,
  indicateRequired = false,
  ToolTip,
  formType = FORM_TYPE.formik,
  formatValue = (value) => value,
  FieldFunctionComponent = () => null,
  fieldComponentProps = {},
  ...props
}) => {
  const { fieldProps, errors, setValue } = useFormField(formType)(props)

  const { name, disabled } = props
  const handleChange = (
    value:
      | string
      | number
      | boolean
      | Date
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    let formattedValue: string | number | boolean | Date
    if (isChangeEvent(value)) {
      formattedValue = formatValue(value.target.value)
    } else {
      formattedValue = formatValue(value)
    }

    setValue(name, formattedValue)
  }

  const clearField = () => {
    setValue(name, null)
  }
  const justifyContent = ToolTip ? 'flex-start' : 'space-between'

  return (
    <>
      <Alignment justifyContent={justifyContent} alignItems="center">
        {label && (
          <Label htmlFor={name} required={indicateRequired} disabled={disabled}>
            {label}
          </Label>
        )}
        {ToolTip && <ToolTip />}
        {FieldFunctionComponent && <FieldFunctionComponent />}
      </Alignment>

      <Component
        id={label && name}
        name={name}
        {...fieldProps}
        {...fieldComponentProps}
        {...props}
        error={errors}
        onChange={handleChange}
        onClear={clearField}
      />
    </>
  )
}

Field.propTypes = {
  Component: PropTypes.func.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  indicateRequired: PropTypes.bool,
  ToolTip: PropTypes.func,
  disabled: PropTypes.bool,
  formatValue: PropTypes.func,
  FieldFunctionComponent: PropTypes.func,
  fieldComponentProps: PropTypes.object,
  formType: PropTypes.oneOf(Object.values(FORM_TYPE)),
}

interface Props {
  Component: React.FC<ComponentProps>
  name: string
  label?: string
  id?: string | number
  FieldFunctionComponent?: React.FC
  indicateRequired?: boolean
  ToolTip?: React.FC
  formatValue?: (
    value: string | number | boolean | Date
  ) => string | number | boolean | Date
  formType?: FormType
  disabled?: boolean
  placeholder?: string
  type?: string
  fieldComponentProps?: ComponentProps
  dateFormat?: string
  locale?: string
  minDate?: Date
  textVariant?: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

interface ComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string | number | symbol]: any
}

function isChangeEvent(e: unknown): e is React.ChangeEvent<HTMLInputElement> {
  // eslint-disable-next-line xss/no-mixed-html
  return (e as React.ChangeEvent<HTMLInputElement>)?.target?.value !== undefined
}

export default Field
