import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const useErrorFocus = () => {
  const { errors, isSubmitting, isValidating, status } = useFormikContext()

  useEffect(() => {
    const fieldErrors = status?.fieldErrors || {}
    const fieldNames = [...Object.keys(errors), ...Object.keys(fieldErrors)]
    if (fieldNames.length && isSubmitting && !isValidating) {
      const selector = `[name="${fieldNames[0]}"]`
      const errorElement = document.querySelector(selector)
      if (errorElement) errorElement.focus()
    }
  }, [errors, isSubmitting, isValidating, status])
}

export default useErrorFocus
