import PropTypes from 'prop-types'
import { TextInput } from '@resident-advisor/design-system'
import { Field } from '@/components/forms'
import CurrencyDto from '@/interfaces/gql/CurrencyDto'
import { FormType } from '@/enums/form-type'
import formatValueWithExponent from '@/components/shared-helpers/format-value-with-exponent'

const PriceField = ({
  name,
  currency,
  ToolTip,
  label,
  formType,
  indicateRequired = false,
  placeholder,
  fieldComponentProps,
  ...props
}: {
  name: string
  label?: string
  ToolTip?: React.FC
  formType?: FormType
  indicateRequired?: boolean
  placeholder?: string
  currency: CurrencyDto
  fieldComponentProps?: unknown
}) => {
  return (
    <Field
      {...props}
      label={label}
      ToolTip={ToolTip}
      name={name}
      Component={TextInput}
      type="number"
      formatValue={(value: string) =>
        formatValueWithExponent(value, currency.exponent)
      }
      indicateRequired={indicateRequired}
      formType={formType}
      placeholder={placeholder}
      fieldComponentProps={fieldComponentProps}
    />
  )
}

PriceField.propTypes = {
  name: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    exponent: PropTypes.number.isRequired,
  }).isRequired,
}

export default PriceField
