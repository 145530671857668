import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import arrayHasData from 'lib/arrayHasData'
import usePreviousValue from '@/hooks/usePreviousValue'

const useGenericErrorHandling = () => {
  const { status, setStatus, values } = useFormikContext()
  const previousValues = usePreviousValue(values)

  useEffect(() => {
    if (arrayHasData(status?.formErrors) && values !== previousValues) {
      setStatus({ formErrors: [] })
    }
  }, [status, previousValues, values, setStatus])
}

export default useGenericErrorHandling
