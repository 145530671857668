import PropTypes from 'prop-types'
import { Alignment } from '@resident-advisor/design-system'
import arrayHasData from 'lib/arrayHasData'
import { useIntl } from 'react-intl'
import messages from '@/messages/errors'
import Error from '../error'

const FormErrors = ({
  errors = [],
  statusCodeMessages = {},
  statusCode = undefined,
  displayProps = {},
  ...props
}) => {
  const intl = useIntl()

  if (arrayHasData(errors)) {
    return (
      <Alignment flexDirection="column" {...displayProps} {...props}>
        {errors.map((error, index) => (
          <Error key={index} pb={2}>
            {error}
          </Error>
        ))}
      </Alignment>
    )
  }

  if (statusCode) {
    return (
      <Error {...displayProps} {...props}>
        {statusCodeMessages[statusCode] ||
          intl.formatMessage(messages.serverError)}
      </Error>
    )
  }

  return null
}

FormErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  statusCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  statusCodeMessages: PropTypes.object,
  displayProps: PropTypes.object,
}

export default FormErrors
