import { Checkbox } from '@resident-advisor/design-system'
import Field from '../field'

const CheckboxField = (props) => {
  return (
    <Field
      {...props}
      Component={Checkbox}
      type="checkbox"
      formatValue={(value) => {
        if (typeof value === 'boolean') {
          return !value
        }
        return !(value === 'true')
      }}
    />
  )
}

export default CheckboxField
