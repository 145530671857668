import FORM_TYPE from '@/enums/form-type'
import useReactHookFormField from './useReactHookFormField'
import useFormikFormField from './useFormikFormField'

const useFormField = (type) => {
  return type === FORM_TYPE.reactHookForm
    ? useReactHookFormField
    : useFormikFormField
}

export default useFormField
