import { useFormContext, get, useController } from 'react-hook-form'

const useReactHookFormField = (props) => {
  const { setValue } = useFormContext()

  const name = props.name

  const {
    field: { onBlur, ref, value },
    fieldState: { isTouched },
    formState: { errors },
  } = useController({ name })

  const fieldProps = { onBlur, ref, value }

  if (typeof value === 'boolean') {
    fieldProps.checked = value
  }

  const setValueWithOptions = (fieldName, fieldValue) => {
    // If the field value is null then it was set by onClear so we don't want to revalidate
    const options = { shouldValidate: !(fieldValue === null) }
    setValue(fieldName, fieldValue, options)
  }

  return {
    fieldProps: { ...fieldProps, touched: isTouched },
    errors: get(errors, name)?.message,
    setValue: setValueWithOptions,
  }
}

export default useReactHookFormField
