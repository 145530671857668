import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Text, variants } from '@resident-advisor/design-system'

const ErrorText = styled(Text)`
  ${({ theme }) =>
    css`
      color: ${theme.colors.accent};
    `};
  display: block;
`

const Error = ({ children, ...props }) =>
  children && (
    <ErrorText variant={variants.text.small} {...props}>
      {children}
    </ErrorText>
  )

Error.propTypes = {
  children: PropTypes.node,
}

export default Error
