import { useField, useFormikContext, setIn } from 'formik'
import regex from 'lib/regex'
import get from 'lodash/get'

const useFormikFormField = (props) => {
  const [field, meta] = useField(props)
  const { errors, status, setStatus, setFieldValue } = useFormikContext()

  const name = props.name
  const error = getError(errors, meta?.error, name)
  const serverErrors = status?.fieldErrors?.[name]

  const setValue = (fieldName, value) => {
    if (serverErrors) {
      setStatus({
        ...status,
        fieldErrors: setIn(status.fieldErrors, name, undefined),
      })
    }

    setFieldValue(fieldName, value)
  }

  return {
    fieldProps: { ...meta, ...field },
    errors: error || serverErrors,
    setValue,
  }
}

/**
 * For array fields where individual field values are just strings or numbers,
 * and you have an outer validation function for the entire array, the inner
 * error messages will show characters from the outer error message (based on index).
 *
 * This function ensures that those characters from outer messages aren't displayed,
 * while retaining the inner error messages.
 *
 * https://github.com/formium/formik/issues/3104
 * https://app.clubhouse.io/resident-advisor/story/24611/event-submission-highlight-event-date-errors-on-startdate-starttime-enddate-endtime-input-fields
 */
const getError = (formErrors, fieldError, name) => {
  if (name.match(regex.objectNestingSeparator)) {
    const outerFieldName = name.split(regex.objectNestingSeparator)[0]
    const outerFieldError = formErrors[outerFieldName]

    if (typeof outerFieldError === 'object') {
      const result = get(formErrors, name)

      return result
    }

    // When no error messages for inner fields
    if (!Array.isArray(outerFieldError)) {
      return undefined
    }
  }

  return fieldError
}

export default useFormikFormField
